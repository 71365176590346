// PAGE TITLE
export const SHIPMENT_TITLE_PREFIX = 'Shipment';

// ROUTES
export const SHIPMENT_ROUTE_PREFIX = 'shipment';
export const SHIPMENT_DETAILS_PATH = `/${SHIPMENT_ROUTE_PREFIX}/:id`;
export const SHIPMENT_DETAILS_ROUTE = `/${SHIPMENT_ROUTE_PREFIX}`;

export const SHIPMENT_PRINT_PATH = `/${SHIPMENT_ROUTE_PREFIX}-print/:id`;
export const SHIPMENT_PRINT_ROUTE = `/${SHIPMENT_ROUTE_PREFIX}-print`;

// SETTINGS ROUTES
export const SHIPPING_SETTINGS_ROUTE_PREFIX = 'settings/shipping-options';

export const CLASS_CODES_TITLE_PREFIX = 'Class Codes';
export const CLASS_CODES_INDEX_ROUTE = `/${SHIPPING_SETTINGS_ROUTE_PREFIX}/class-codes`;
export const CLASS_CODES_FORM_PATH = `/${SHIPPING_SETTINGS_ROUTE_PREFIX}/class-codes/form/:id?`;
export const CLASS_CODES_FORM_ROUTE = `/${SHIPPING_SETTINGS_ROUTE_PREFIX}/class-codes/form`;

export const MAPPER_TITLE_PREFIX = 'Shipping Option Mappers';
export const MAPPER_INDEX_ROUTE = `/${SHIPPING_SETTINGS_ROUTE_PREFIX}/mapper`;

export const MAPPER_FORM_PATH = `/${SHIPPING_SETTINGS_ROUTE_PREFIX}/mapper/:action(add|edit)/:id?`;
export const MAPPER_CREATE_FORM_ROUTE = `/${SHIPPING_SETTINGS_ROUTE_PREFIX}/mapper/add`;
export const MAPPER_EDIT_FORM_ROUTE = `/${SHIPPING_SETTINGS_ROUTE_PREFIX}/mapper/edit`;

export const CARRIERS_TITLE_PREFIX = 'Carriers';
export const CARRIERS_INDEX_ROUTE = `/${SHIPPING_SETTINGS_ROUTE_PREFIX}/carriers`;
export const CARRIERS_FORM_PATH = `/${SHIPPING_SETTINGS_ROUTE_PREFIX}/carriers/form/:id?`;
export const CARRIERS_FORM_ROUTE = `/${SHIPPING_SETTINGS_ROUTE_PREFIX}/carriers/form`;

export const PARCELS_TITLE_PREFIX = 'Parcels';
export const PARCELS_INDEX_ROUTE = `/${SHIPPING_SETTINGS_ROUTE_PREFIX}/parcels`;
export const PARCELS_FORM_PATH = `/${SHIPPING_SETTINGS_ROUTE_PREFIX}/parcels/form/:id?`;
export const PARCELS_FORM_ROUTE = `/${SHIPPING_SETTINGS_ROUTE_PREFIX}/parcels/form`;

export const SHIPPING_SETTINGS_TITLE_PREFIX = 'International Shipping Settings';
export const SHIPPING_SETTINGS_ROUTE = `/${SHIPPING_SETTINGS_ROUTE_PREFIX}/general`;
