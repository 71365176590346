import UserModel from '../../../UserModel';
import {
    TITLE_PREFIX,
    PO_INDEX_ROUTE,
    PO_DETAILS_PATH,
    PO_DETAILS_ROUTE,
    PO_PRINT_PATH,
    PO_PRINT_ROUTE,
    PO_FORM_PATH,
    PO_CREATE_FORM_ROUTE,
    PO_EDIT_FORM_ROUTE,
    PO_RESTOCK_ROUTE,
    PO_RESTOCK_PATH,
} from './consts';

import { EMPTY_TITLE_CRUMB } from '../constants';

import { PRODUCTS_DETAILS_ROUTE, PRODUCTS_STOCK_ROUTE } from '../products/consts';
import { ORDERS_DETAILS_ROUTE } from '../orders/consts';
import { VENDOR_DETAILS_ROUTE, VENDOR_PRODUCTS_GRID_ROUTE } from '../vendors/consts';
import withSettingsTabsWrapper from '../settings/components/withSettingsTabsWrapper';

export default (app) => ({
    purchaseOrders: {
        path: PO_INDEX_ROUTE,
        title: `${TITLE_PREFIX}s`,
        breadcrumbConfig: { title: `${TITLE_PREFIX}s`, clearPath: true },
        props: () => ({
            detailsPageRoute: PO_DETAILS_ROUTE,
            addFormPageRoute: PO_CREATE_FORM_ROUTE,
            updateFormPageRoute: PO_EDIT_FORM_ROUTE,
            orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
            vendorDetailsPageRoute: VENDOR_DETAILS_ROUTE,
            restockPOPageRoute: PO_RESTOCK_ROUTE,
        }),
        permission: ['purchase orders.index'],
        loader: () => import('@skunexus/purchase-orders-grid'),
    },
    purchaseOrdersRestock: {
        path: PO_RESTOCK_PATH,
        title: `${TITLE_PREFIX} Restock`,
        props: () => ({
            indexPageRoute: PO_INDEX_ROUTE,
            productStockPageRoute: PRODUCTS_STOCK_ROUTE,
        }),
        permission: ['purchase orders.create', 'purchase orders.update'],
        loader: () => import('@skunexus/purchase-orders-restock'),
    },
    purchaseOrderForm: {
        path: PO_FORM_PATH,
        title: `${TITLE_PREFIX} Form`,
        permission: ['purchase orders.create', 'purchase orders.update'],
        breadcrumbConfig: { title: 'Create Purchase Order', isReplaceble: true },
        props: () => ({
            indexPageRoute: PO_INDEX_ROUTE,
            detailsPageRoute: PO_DETAILS_ROUTE,
            productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
        }),
        loader: () => import('@skunexus/purchase-order-form'),
    },
    purchaseOrderOverview: {
        path: PO_DETAILS_PATH,
        title: `${TITLE_PREFIX} Overview`,
        permission: ['purchase orders.show'],
        breadcrumbConfig: { title: EMPTY_TITLE_CRUMB, isReplaceble: true },
        props: () => ({
            updateFormPageRoute: PO_EDIT_FORM_ROUTE,
            productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
            vendorProductDetailsPageRoute: VENDOR_PRODUCTS_GRID_ROUTE,
            vendorDetailsPageRoute: VENDOR_DETAILS_ROUTE,
            orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
            printPageRoute: PO_PRINT_ROUTE,
            permissions: {
                allowedStates: app.general.getItem('modules.purchaseOrder.userStates') || [],
                viewAttributes: !UserModel.isVendor(),
                editAttributes: !UserModel.isVendor(),
                orderOnlyAdmin: !UserModel.isVendor(),
            },
        }),
        loader: () => import('@skunexus/purchase-order-overview'),
    },
    purchaseOrderPrint: {
        path: PO_PRINT_PATH,
        title: `${TITLE_PREFIX} Print`,
        permission: ['purchase orders.show'],
        breadcrumbConfig: { title: 'Print', isReplaceble: true },
        loader: () => import('@skunexus/purchase-order-print'),
    },

    purchaseOrderStatusesGrid: {
        path: app.settingsPurchaseOrdersTabs.getItem('statuses').link,
        title: app.settingsPurchaseOrdersTabs.getItem('statuses').title,
        permission: app.settingsPurchaseOrdersTabs.getItem('statuses').permission,
        breadcrumbConfig: {
            name: `${TITLE_PREFIX} - ${app.settingsPurchaseOrdersTabs.getItem('statuses').label}`,
            clearPath: true,
        },
        loader: () => import('@skunexus/purchase-order-status-grid'),
        render: withSettingsTabsWrapper(app),
    },
    purchaseOrderNumberGrid: {
        path: app.settingsPurchaseOrdersTabs.getItem('poNumber').link,
        title: app.settingsPurchaseOrdersTabs.getItem('poNumber').title,
        permission: app.settingsPurchaseOrdersTabs.getItem('poNumber').permission,
        breadcrumbConfig: { title: `${app.settingsPurchaseOrdersTabs.getItem('poNumber').label}` },
        loader: () => import('@skunexus/purchase-order-po-number-grid'),
        render: withSettingsTabsWrapper(app),
    },
});
