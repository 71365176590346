import {
    QUEUE_FAILED_JOBS_LABEL,
    QUEUE_FAILED_JOBS_TITLE,
    QUEUE_FAILED_JOBS_ROUTE,
    QUEUE_SPAWNED_JOBS_LABEL,
    QUEUE_SPAWNED_JOBS_TITLE,
    QUEUE_SPAWNED_JOBS_ROUTE,
} from './consts';

export default () => ({
    failed: {
        id: QUEUE_FAILED_JOBS_LABEL,
        label: QUEUE_FAILED_JOBS_LABEL,
        title: QUEUE_FAILED_JOBS_TITLE,
        link: QUEUE_FAILED_JOBS_ROUTE,
        permission: ['queue.index'],
        order: 10,
    },
    spawned: {
        id: QUEUE_SPAWNED_JOBS_LABEL,
        label: QUEUE_SPAWNED_JOBS_LABEL,
        title: QUEUE_SPAWNED_JOBS_TITLE,
        link: QUEUE_SPAWNED_JOBS_ROUTE,
        permission: ['queue.index'],
        order: 20,
    },
});
