import values from 'lodash/values';

import {
    PICKUP_TITLE_PREFIX,
    PICKUP_CANCELLED_ROUTE,
    PICKUP_DASHBOARD_ROUTE,
    PICKUP_FINALIZED_ROUTE,
    PICKUP_GROUP_PICK_ROUTE,
    PICKUP_HOSPITAL_ROUTE,
    PICKUP_WAVES_ROUTE,
    PICKUP_PICK_ROUTE,
    PICKUP_PACK_ROUTE,
    PICKUP_READY_ROUTE,
} from './consts';

import { combinePermissions } from '../../../utils/helpers';

export default (app) => ({
    main: {
        id: 'dashboard',
        label: 'Dashboard',
        title: `${PICKUP_TITLE_PREFIX}`,
        link: PICKUP_DASHBOARD_ROUTE,
        permission: 'fulfillments.index',
        order: 10,
    },
    pick: {
        id: 'pick',
        label: 'Pick',
        title: `${PICKUP_TITLE_PREFIX} > Pick`,
        link: PICKUP_PICK_ROUTE,
        permission: 'fulfillments.picking',
        order: 20,
    },
    groupPick: {
        id: 'groupPick',
        label: 'Group Pick',
        title: `${PICKUP_TITLE_PREFIX} > Group Pick`,
        link: PICKUP_GROUP_PICK_ROUTE,
        permission: 'fulfillments.picking',
        order: 30,
    },
    waves: {
        id: 'waves',
        label: 'Waves',
        title: `${PICKUP_TITLE_PREFIX} > Waves`,
        link: PICKUP_WAVES_ROUTE,
        permission: () => combinePermissions(values(app.fulfillmentPickupWavesTabs.getAll())),
        order: 40,
        children: values(app.fulfillmentPickupWavesTabs.getAll()),
    },
    pack: {
        id: 'pack',
        label: 'Pack',
        title: `${PICKUP_TITLE_PREFIX} > Pack`,
        link: PICKUP_PACK_ROUTE,
        permission: 'fulfillments.packing',
        order: 50,
    },
    ready: {
        id: 'readyForPickup',
        label: 'Ready For Pickup',
        title: `${PICKUP_TITLE_PREFIX} > Ready For Pickup`,
        link: PICKUP_READY_ROUTE,
        permission: 'fulfillments.index',
        order: 60,
    },
    hospital: {
        id: 'hospital',
        label: 'Hospital',
        title: `${PICKUP_TITLE_PREFIX} > Hospital`,
        link: PICKUP_HOSPITAL_ROUTE,
        permission: 'fulfillments.hospital',
        order: 70,
    },
    cancelled: {
        id: 'Cancelled',
        label: 'Cancelled',
        title: `${PICKUP_TITLE_PREFIX} > Cancelled`,
        link: PICKUP_CANCELLED_ROUTE,
        permission: 'fulfillments.index',
        order: 80,
    },
    finalized: {
        id: 'finalized',
        label: 'Finalized',
        title: `${PICKUP_TITLE_PREFIX} > Finalized`,
        permission: 'fulfillments.index',
        link: PICKUP_FINALIZED_ROUTE,
        order: 90,
    },
});
