import {
    CUSTOMER_ATTRIBUTES_FORM_PATH,
    CUSTOMER_ATTRIBUTES_FORM_ROUTE,
    CUSTOMER_ATTRIBUTES_FORM_TITLE,
    CUSTOMER_ATTRIBUTES_GRID_ROUTE,
    PO_ATTRIBUTES_FORM_PATH,
    PO_ATTRIBUTES_FORM_ROUTE,
    PO_ATTRIBUTES_FORM_TITLE,
    PO_ATTRIBUTES_GRID_ROUTE,
    PRODUCT_ATTRIBUTES_FORM_PATH,
    PRODUCT_ATTRIBUTES_FORM_ROUTE,
    PRODUCT_ATTRIBUTES_FORM_TITLE,
    PRODUCT_ATTRIBUTES_GRID_ROUTE,
    ORDER_ATTRIBUTES_FORM_PATH,
    ORDER_ATTRIBUTES_FORM_ROUTE,
    ORDER_ATTRIBUTES_FORM_TITLE,
    ORDER_ATTRIBUTES_GRID_ROUTE,
    USER_ATTRIBUTES_FORM_PATH,
    USER_ATTRIBUTES_FORM_ROUTE,
    USER_ATTRIBUTES_FORM_TITLE,
    USER_ATTRIBUTES_GRID_ROUTE,
    VENDOR_ATTRIBUTES_FORM_PATH,
    VENDOR_ATTRIBUTES_FORM_ROUTE,
    VENDOR_ATTRIBUTES_FORM_TITLE,
    VENDOR_ATTRIBUTES_GRID_ROUTE,
    VENDOR_PRODUCT_ATTRIBUTES_FORM_PATH,
    VENDOR_PRODUCT_ATTRIBUTES_FORM_ROUTE,
    VENDOR_PRODUCT_ATTRIBUTES_FORM_TITLE,
    VENDOR_PRODUCT_ATTRIBUTES_GRID_ROUTE,
    WAREHOUSE_ATTRIBUTES_FORM_PATH,
    WAREHOUSE_ATTRIBUTES_FORM_ROUTE,
    WAREHOUSE_ATTRIBUTES_FORM_TITLE,
    WAREHOUSE_ATTRIBUTES_GRID_ROUTE,
    PRODUCT_ATTRIBUTES_CODE,
    ORDER_ATTRIBUTES_CODE,
    PO_ATTRIBUTES_CODE,
    VENDOR_ATTRIBUTES_CODE,
    USER_ATTRIBUTES_CODE,
    WAREHOUSE_ATTRIBUTES_CODE,
    CUSTOMER_ATTRIBUTES_CODE,
    VENDOR_PRODUCT_ATTRIBUTES_CODE,
} from './consts';

import withSettingsTabsWrapper from '../settings/components/withSettingsTabsWrapper';
import { withFormRenderWrapper } from '../../../HOCs/FormRenderWrapper';
import { hasAnyPermissions } from '../../../utils/helpers';

export default (app) => ({
    settingsProductAttributes: {
        name: 'settingsCustomAttributesProduct',
        path: app.settingsAttributesTabs.getItem('productAttributes').link,
        title: app.settingsAttributesTabs.getItem('productAttributes').title,
        permission: app.settingsAttributesTabs.getItem('productAttributes').permission,
        breadcrumbConfig: {
            name: `${app.settingsAttributesTabs.getItem('productAttributes').label}`,
            isTab: true,
            clearPath: true,
        },
        props: () => ({
            formPageRoute: PRODUCT_ATTRIBUTES_FORM_ROUTE,
            domain: 'product',
            entityName: 'Product',
            basePath: 'products',
            permissions: {
                canAdd: hasAnyPermissions('products - custom attributes.field create'),
                canEdit: hasAnyPermissions('products - custom attributes.field update'),
                canDelete: hasAnyPermissions('products - custom attributes.field delete')
            }
        }),
        loader: () => import('@skunexus/settings-custom-attributes-product'),
        render: withSettingsTabsWrapper(app),
    },
    settingsProductAttributeForm: {
        name: 'settingsCustomAttributesForm',
        path: PRODUCT_ATTRIBUTES_FORM_PATH,
        title: PRODUCT_ATTRIBUTES_FORM_TITLE,
        breadcrumbConfig: { title: 'Add Product Attribute', isReplaceble: true },
        permission: [
            `${PRODUCT_ATTRIBUTES_CODE} - custom attributes.field create`,
            `${PRODUCT_ATTRIBUTES_CODE} - custom attributes.field update`,
        ],
        props: () => ({
            indexPageRoute: PRODUCT_ATTRIBUTES_GRID_ROUTE,
            domain: 'product',
            entityName: 'Product',
            basePath: 'products',
            permissions: {
                canAdd: hasAnyPermissions('products - custom attributes.field create'),
                canEdit: hasAnyPermissions('products - custom attributes.field update'),
            }
        }),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
        render: withFormRenderWrapper(app)
    },
    settingsOrderAttributes: {
        name: 'settingsCustomAttributesOrder',
        path: app.settingsAttributesTabs.getItem('orderAttributes').link,
        title: app.settingsAttributesTabs.getItem('orderAttributes').title,
        permission: app.settingsAttributesTabs.getItem('orderAttributes').permission,
        breadcrumbConfig: {
            title: `${app.settingsAttributesTabs.getItem('orderAttributes').label}`,
            isTab: true,
            clearPath: true,
        },
        props: () => ({
            formPageRoute: ORDER_ATTRIBUTES_FORM_ROUTE,
            domain: 'order',
            entityName: 'Order',
            basePath: 'orders',
            permissions: {
                canAdd: hasAnyPermissions('orders - custom attributes.field create'),
                canEdit: hasAnyPermissions('orders - custom attributes.field update'),
                canDelete: hasAnyPermissions('orders - custom attributes.field delete')
            }
        }),
        loader: () => import('@skunexus/settings-custom-attributes-order'),
        render: withSettingsTabsWrapper(app),
    },
    settingsOrderAttributeForm: {
        name: 'settingsCustomAttributesForm',
        path: ORDER_ATTRIBUTES_FORM_PATH,
        title: ORDER_ATTRIBUTES_FORM_TITLE,
        permission: [
            `${ORDER_ATTRIBUTES_CODE} - custom attributes.field create`,
            `${ORDER_ATTRIBUTES_CODE} - custom attributes.field update`,
        ],
        breadcrumbConfig: { title: 'Add Order Attributes', isReplaceble: true },
        props: () => ({
            indexPageRoute: ORDER_ATTRIBUTES_GRID_ROUTE,
            domain: 'order',
            entityName: 'Order',
            basePath: 'orders',
            permissions: {
                canAdd: hasAnyPermissions('orders - custom attributes.field create'),
                canEdit: hasAnyPermissions('orders - custom attributes.field update'),
            }
        }),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
        render: withFormRenderWrapper(app)
    },
    settingsPOAttributes: {
        name: 'settingsCustomAttributesPurchaseOrder',
        path: app.settingsAttributesTabs.getItem('poAttributes').link,
        title: app.settingsAttributesTabs.getItem('poAttributes').title,
        permission: app.settingsAttributesTabs.getItem('poAttributes').permission,
        breadcrumbConfig: {
            title: `${app.settingsAttributesTabs.getItem('poAttributes').label}`,
            isTab: true,
            clearPath: true,
        },
        props: () => ({
            formPageRoute: PO_ATTRIBUTES_FORM_ROUTE,
            domain: 'purchaseOrder',
            entityName: 'Purchase Order',
            basePath: 'purchase-order',
            permissions: {
                canAdd: hasAnyPermissions('purchase orders - custom attributes.field create'),
                canEdit: hasAnyPermissions('purchase orders - custom attributes.field update'),
                canDelete: hasAnyPermissions('purchase orders - custom attributes.field delete')
            }
        }),
        loader: () => import('@skunexus/settings-custom-attributes-purchase-order'),
        render: withSettingsTabsWrapper(app),
    },
    settingsPOAttributeForm: {
        name: 'settingsCustomAttributesForm',
        path: PO_ATTRIBUTES_FORM_PATH,
        title: PO_ATTRIBUTES_FORM_TITLE,
        breadcrumbConfig: { title: 'Add Purchase Order Attribute', isReplaceble: true },
        permission: [
            `${PO_ATTRIBUTES_CODE} - custom attributes.field create`,
            `${PO_ATTRIBUTES_CODE} - custom attributes.field update`,
        ],
        props: () => ({
            indexPageRoute: PO_ATTRIBUTES_GRID_ROUTE,
            domain: 'purchaseOrder',
            entityName: 'Purchase Order',
            basePath: 'purchase-order',
            permissions: {
                canAdd: hasAnyPermissions('purchase orders - custom attributes.field create'),
                canEdit: hasAnyPermissions('purchase orders - custom attributes.field update'),
            }
        }),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
        render: withFormRenderWrapper(app)
    },
    settingsVendorAttributes: {
        name: 'settingsCustomAttributesVendor',
        path: app.settingsAttributesTabs.getItem('vendorAttributes').link,
        title: app.settingsAttributesTabs.getItem('vendorAttributes').title,
        permission: app.settingsAttributesTabs.getItem('vendorAttributes').permission,
        breadcrumbConfig: {
            name: `${app.settingsAttributesTabs.getItem('vendorAttributes').label}`,
            isTab: true,
            clearPath: true,
        },
        props: () => ({
            formPageRoute: VENDOR_ATTRIBUTES_FORM_ROUTE,
            domain: 'vendor',
            entityName: 'Vendor',
            basePath: 'vendors',
            permissions: {
                canAdd: hasAnyPermissions('vendors - custom attributes.field create'),
                canEdit: hasAnyPermissions('vendors - custom attributes.field update'),
                canDelete: hasAnyPermissions('vendors - custom attributes.field delete')
            }
        }),
        loader: () => import('@skunexus/settings-custom-attributes-vendor'),
        render: withSettingsTabsWrapper(app),
    },
    settingsVendorAttributeForm: {
        name: 'settingsCustomAttributesForm',
        path: VENDOR_ATTRIBUTES_FORM_PATH,
        title: VENDOR_ATTRIBUTES_FORM_TITLE,
        breadcrumbConfig: { title: 'Add Vendor Attributes', isReplaceble: true },
        permission: [
            `${VENDOR_ATTRIBUTES_CODE} - custom attributes.field create`,
            `${VENDOR_ATTRIBUTES_CODE} - custom attributes.field update`,
        ],
        props: () => ({
            indexPageRoute: VENDOR_ATTRIBUTES_GRID_ROUTE,
            domain: 'vendor',
            entityName: 'Vendor',
            basePath: 'vendors',
            permissions: {
                canAdd: hasAnyPermissions('vendors - custom attributes.field create'),
                canEdit: hasAnyPermissions('vendors - custom attributes.field update'),
            }
        }),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
        render: withFormRenderWrapper(app)
    },
    settingsUserAttributes: {
        name: 'settingsCustomAttributesUser',
        path: app.settingsAttributesTabs.getItem('userAttributes').link,
        title: app.settingsAttributesTabs.getItem('userAttributes').title,
        permission: app.settingsAttributesTabs.getItem('userAttributes').permission,
        breadcrumbConfig: {
            title: `${app.settingsAttributesTabs.getItem('userAttributes').label}`,
            isTab: true,
            clearPath: true,
        },
        props: () => ({
            formPageRoute: USER_ATTRIBUTES_FORM_ROUTE,
            domain: 'user',
            entityName: 'User',
            basePath: 'users',
            permissions: {
                canAdd: hasAnyPermissions('users - custom attributes.field create'),
                canEdit: hasAnyPermissions('users - custom attributes.field update'),
                canDelete: hasAnyPermissions('users - custom attributes.field delete')
            }
        }),
        loader: () => import('@skunexus/settings-custom-attributes-user'),
        render: withSettingsTabsWrapper(app),
    },
    settingsUserAttributeForm: {
        name: 'settingsCustomAttributesForm',
        path: USER_ATTRIBUTES_FORM_PATH,
        title: USER_ATTRIBUTES_FORM_TITLE,
        breadcrumbConfig: { title: 'Add User Attributes', isReplaceble: true },
        permission: [
            `${USER_ATTRIBUTES_CODE} - custom attributes.field create`,
            `${USER_ATTRIBUTES_CODE} - custom attributes.field update`,
        ],
        props: () => ({
            indexPageRoute: USER_ATTRIBUTES_GRID_ROUTE,
            domain: 'user',
            entityName: 'User',
            basePath: 'users',
            permissions: {
                canAdd: hasAnyPermissions('users - custom attributes.field create'),
                canEdit: hasAnyPermissions('users - custom attributes.field update'),
            }
        }),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
        render: withFormRenderWrapper(app)
    },
    settingsWarehouseAttributes: {
        name: 'settingsCustomAttributesWarehouse',
        path: app.settingsAttributesTabs.getItem('warehouseAttributes').link,
        title: app.settingsAttributesTabs.getItem('warehouseAttributes').title,
        permission: app.settingsAttributesTabs.getItem('warehouseAttributes').permission,
        breadcrumbConfig: {
            name: `${app.settingsAttributesTabs.getItem('warehouseAttributes').label}`,
            isTab: true,
            clearPath: true,
        },
        props: () => ({
            formPageRoute: WAREHOUSE_ATTRIBUTES_FORM_ROUTE,
            domain: 'warehouse',
            entityName: 'Warehouse',
            basePath: 'warehouses',
            permissions: {
                canAdd: hasAnyPermissions('warehouses - custom attributes.field create'),
                canEdit: hasAnyPermissions('warehouses - custom attributes.field update'),
                canDelete: hasAnyPermissions('warehouses - custom attributes.field delete')
            }
        }),
        loader: () => import('@skunexus/settings-custom-attributes-warehouse'),
        render: withSettingsTabsWrapper(app),
    },
    settingsWarehouseAttributeForm: {
        name: 'settingsCustomAttributesForm',
        path: WAREHOUSE_ATTRIBUTES_FORM_PATH,
        title: WAREHOUSE_ATTRIBUTES_FORM_TITLE,
        breadcrumbConfig: { title: 'Add Warehouse Attributes', isReplaceble: true },
        permission: [
            `${WAREHOUSE_ATTRIBUTES_CODE} - custom attributes.field create`,
            `${WAREHOUSE_ATTRIBUTES_CODE} - custom attributes.field update`,
        ],
        props: () => ({
            indexPageRoute: WAREHOUSE_ATTRIBUTES_GRID_ROUTE,
            domain: 'warehouse',
            entityName: 'Warehouse',
            basePath: 'warehouses',
            permissions: {
                canAdd: hasAnyPermissions('warehouses - custom attributes.field create'),
                canEdit: hasAnyPermissions('warehouses - custom attributes.field update'),
            }
        }),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
        render: withFormRenderWrapper(app)
    },
    settingsCustomerAttributes: {
        name: 'settingsCustomAttributesCustomer',
        path: app.settingsAttributesTabs.getItem('customerAttributes').link,
        title: app.settingsAttributesTabs.getItem('customerAttributes').title,
        permission: app.settingsAttributesTabs.getItem('customerAttributes').permission,
        breadcrumbConfig: {
            name: `${app.settingsAttributesTabs.getItem('customerAttributes').label}`,
            isTab: true,
            clearPath: true,
        },
        props: () => ({
            formPageRoute: CUSTOMER_ATTRIBUTES_FORM_ROUTE,
            domain: 'customer',
            entityName: 'Customer',
            basePath: 'customers',
            permissions: {
                canAdd: hasAnyPermissions('customers - custom attributes.field create'),
                canEdit: hasAnyPermissions('customers - custom attributes.field update'),
                canDelete: hasAnyPermissions('customers - custom attributes.field delete')
            }
        }),
        loader: () => import('@skunexus/settings-custom-attributes-customer'),
        render: withSettingsTabsWrapper(app),
    },
    settingsCustomerAttributeForm: {
        name: 'settingsCustomAttributesForm',
        path: CUSTOMER_ATTRIBUTES_FORM_PATH,
        title: CUSTOMER_ATTRIBUTES_FORM_TITLE,
        breadcrumbConfig: { title: 'Add Customer Attributes', isReplaceble: true },
        permission: [
            `${CUSTOMER_ATTRIBUTES_CODE} - custom attributes.field create`,
            `${CUSTOMER_ATTRIBUTES_CODE} - custom attributes.field update`,
        ],
        props: () => ({
            indexPageRoute: CUSTOMER_ATTRIBUTES_GRID_ROUTE,
            domain: 'customer',
            entityName: 'Customer',
            basePath: 'customers',
            permissions: {
                canAdd: hasAnyPermissions('customers - custom attributes.field create'),
                canEdit: hasAnyPermissions('customers - custom attributes.field update'),
            }
        }),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
        render: withFormRenderWrapper(app)
    },
    settingsVendorProductAttributes: {
        name: 'settingsCustomAttributesVendorProducts',
        path: app.settingsAttributesTabs.getItem('vendorProductAttributes').link,
        title: app.settingsAttributesTabs.getItem('vendorProductAttributes').title,
        permission: app.settingsAttributesTabs.getItem('vendorProductAttributes').permission,
        breadcrumbConfig: {
            name: `${app.settingsAttributesTabs.getItem('vendorProductAttributes').label}`,
            isTab: true,
            clearPath: true,
        },
        props: () => ({
            formPageRoute: VENDOR_PRODUCT_ATTRIBUTES_FORM_ROUTE,
            domain: 'vendorProduct',
            entityName: 'Vendor Product',
            basePath: 'vendor-products',
            permissions: {
                canAdd: hasAnyPermissions('vendor products - custom attributes.field create'),
                canEdit: hasAnyPermissions('vendor products - custom attributes.field update'),
                canDelete: hasAnyPermissions('vendor products - custom attributes.field delete')
            }
        }),
        loader: () => import('@skunexus/settings-custom-attributes-vendor-product'),
        render: withSettingsTabsWrapper(app),
    },
    settingsVendorProductAttributesForm: {
        name: 'settingsVendorProductAttributesForm',
        path: VENDOR_PRODUCT_ATTRIBUTES_FORM_PATH,
        title: VENDOR_PRODUCT_ATTRIBUTES_FORM_TITLE,
        breadcrumbConfig: { title: 'Add Vendor Product Attributes', isReplaceble: true },
        permission: [
            `${VENDOR_PRODUCT_ATTRIBUTES_CODE} - custom attributes.field create`,
            `${VENDOR_PRODUCT_ATTRIBUTES_CODE} - custom attributes.field update`,
        ],
        props: () => ({
            indexPageRoute: VENDOR_PRODUCT_ATTRIBUTES_GRID_ROUTE,
            domain: 'vendorProduct',
            entityName: 'Vendor Product',
            basePath: 'vendor-products',
            permissions: {
                canAdd: hasAnyPermissions('vendor products - custom attributes.field create'),
                canEdit: hasAnyPermissions('vendor products - custom attributes.field update'),
            }
        }),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
        render: withFormRenderWrapper(app)
    },
});
