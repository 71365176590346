import { Redirect } from 'react-router-dom';

import { DASHBOARD_ROUTE } from '../setup/modules/dashboard/consts';

export const withFormRenderWrapper = () => (Component) => {
    function WrappedComponent(props) {
        const { params = {}, permissions = {} } = props;

        const { id } = params;
        const { canAdd = false, canEdit = false } = permissions;

        if ((!id && !canAdd) || (!!id && !canEdit)) {
            return <Redirect to={DASHBOARD_ROUTE} />;
        }
        return <Component {...props} />;
    }

    return WrappedComponent;
}