import {
    CUSTOMER_ATTRIBUTES_CODE,
    CUSTOMER_ATTRIBUTES_GRID_ROUTE,
    CUSTOMER_ATTRIBUTES_GRID_TITLE,
    PO_ATTRIBUTES_CODE,
    PO_ATTRIBUTES_GRID_ROUTE,
    PO_ATTRIBUTES_GRID_TITLE,
    PRODUCT_ATTRIBUTES_CODE,
    PRODUCT_ATTRIBUTES_GRID_ROUTE,
    PRODUCT_ATTRIBUTES_GRID_TITLE,
    ORDER_ATTRIBUTES_CODE,
    ORDER_ATTRIBUTES_GRID_ROUTE,
    ORDER_ATTRIBUTES_GRID_TITLE,
    USER_ATTRIBUTES_CODE,
    USER_ATTRIBUTES_GRID_ROUTE,
    USER_ATTRIBUTES_GRID_TITLE,
    VENDOR_ATTRIBUTES_CODE,
    VENDOR_ATTRIBUTES_GRID_ROUTE,
    VENDOR_ATTRIBUTES_GRID_TITLE,
    VENDOR_PRODUCT_ATTRIBUTES_GRID_ROUTE,
    VENDOR_PRODUCT_ATTRIBUTES_GRID_TITLE,
    WAREHOUSE_ATTRIBUTES_CODE,
    WAREHOUSE_ATTRIBUTES_GRID_ROUTE,
    WAREHOUSE_ATTRIBUTES_GRID_TITLE,
    VENDOR_PRODUCT_ATTRIBUTES_CODE,
} from './consts';

export default () => ({
    orderAttributes: {
        id: ORDER_ATTRIBUTES_GRID_TITLE,
        label: ORDER_ATTRIBUTES_GRID_TITLE,
        title: ORDER_ATTRIBUTES_GRID_TITLE,
        link: ORDER_ATTRIBUTES_GRID_ROUTE,
        permission: [
            `${ORDER_ATTRIBUTES_CODE} - custom attributes.field create`,
            `${ORDER_ATTRIBUTES_CODE} - custom attributes.field update`,
            `${ORDER_ATTRIBUTES_CODE} - custom attributes.field delete`,
        ],
        order: 10,
    },
    userAttributes: {
        id: USER_ATTRIBUTES_GRID_TITLE,
        label: USER_ATTRIBUTES_GRID_TITLE,
        title: USER_ATTRIBUTES_GRID_TITLE,
        link: USER_ATTRIBUTES_GRID_ROUTE,
        permission: [
            `${USER_ATTRIBUTES_CODE} - custom attributes.field create`,
            `${USER_ATTRIBUTES_CODE} - custom attributes.field update`,
            `${USER_ATTRIBUTES_CODE} - custom attributes.field delete`,
        ],
        order: 20,
    },
    productAttributes: {
        id: PRODUCT_ATTRIBUTES_GRID_TITLE,
        label: PRODUCT_ATTRIBUTES_GRID_TITLE,
        title: PRODUCT_ATTRIBUTES_GRID_TITLE,
        link: PRODUCT_ATTRIBUTES_GRID_ROUTE,
        permission: [
            `${PRODUCT_ATTRIBUTES_CODE} - custom attributes.field create`,
            `${PRODUCT_ATTRIBUTES_CODE} - custom attributes.field update`,
            `${PRODUCT_ATTRIBUTES_CODE} - custom attributes.field delete`,
        ],
        order: 30,
    },
    customerAttributes: {
        id: CUSTOMER_ATTRIBUTES_GRID_TITLE,
        label: CUSTOMER_ATTRIBUTES_GRID_TITLE,
        title: CUSTOMER_ATTRIBUTES_GRID_TITLE,
        link: CUSTOMER_ATTRIBUTES_GRID_ROUTE,
        permission: [
            `${CUSTOMER_ATTRIBUTES_CODE} - custom attributes.field create`,
            `${CUSTOMER_ATTRIBUTES_CODE} - custom attributes.field update`,
            `${CUSTOMER_ATTRIBUTES_CODE} - custom attributes.field delete`,
        ],
        order: 40,
    },
    vendorAttributes: {
        id: VENDOR_ATTRIBUTES_GRID_TITLE,
        label: VENDOR_ATTRIBUTES_GRID_TITLE,
        title: VENDOR_ATTRIBUTES_GRID_TITLE,
        link: VENDOR_ATTRIBUTES_GRID_ROUTE,
        permission: [
            `${VENDOR_ATTRIBUTES_CODE} - custom attributes.field create`,
            `${VENDOR_ATTRIBUTES_CODE} - custom attributes.field update`,
            `${VENDOR_ATTRIBUTES_CODE} - custom attributes.field delete`,
        ],
        order: 50,
    },
    vendorProductAttributes: {
        id: VENDOR_PRODUCT_ATTRIBUTES_GRID_TITLE,
        label: VENDOR_PRODUCT_ATTRIBUTES_GRID_TITLE,
        title: VENDOR_PRODUCT_ATTRIBUTES_GRID_TITLE,
        link: VENDOR_PRODUCT_ATTRIBUTES_GRID_ROUTE,
        permission: [
            `${VENDOR_PRODUCT_ATTRIBUTES_CODE} - custom attributes.field create`,
            `${VENDOR_PRODUCT_ATTRIBUTES_CODE} - custom attributes.field update`,
            `${VENDOR_PRODUCT_ATTRIBUTES_CODE} - custom attributes.field delete`,
        ],
        order: 60,
    },
    warehouseAttributes: {
        id: WAREHOUSE_ATTRIBUTES_GRID_TITLE,
        label: WAREHOUSE_ATTRIBUTES_GRID_TITLE,
        title: WAREHOUSE_ATTRIBUTES_GRID_TITLE,
        link: WAREHOUSE_ATTRIBUTES_GRID_ROUTE,
        permission: [
            `${WAREHOUSE_ATTRIBUTES_CODE} - custom attributes.field create`,
            `${WAREHOUSE_ATTRIBUTES_CODE} - custom attributes.field update`,
            `${WAREHOUSE_ATTRIBUTES_CODE} - custom attributes.field delete`,
        ],
        order: 80,
    },
    poAttributes: {
        id: PO_ATTRIBUTES_GRID_TITLE,
        label: PO_ATTRIBUTES_GRID_TITLE,
        title: PO_ATTRIBUTES_GRID_TITLE,
        link: PO_ATTRIBUTES_GRID_ROUTE,
        permission: [
            `${PO_ATTRIBUTES_CODE} - custom attributes.field create`,
            `${PO_ATTRIBUTES_CODE} - custom attributes.field update`,
            `${PO_ATTRIBUTES_CODE} - custom attributes.field delete`,
        ],
        order: 90,
    },
});
