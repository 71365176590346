import get from 'lodash/get';
import UserModel from '../../../UserModel';

import {
    TITLE_PREFIX,
    PRODUCTIVITY_REPORT_TITLE_PREFIX,
    PROFILE_TITLE_PREFIX,
    USERS_INDEX_ROUTE,
    USERS_DETAILS_PATH,
    USERS_DETAILS_ROUTE,
    USERS_CHANGE_PASSWORD_FORM_PATH,
    USERS_CHANGE_PASSWORD_FORM_ROUTE,
    USERS_FORM_PATH,
    USERS_CREATE_FORM_ROUTE,
    USERS_EDIT_FORM_ROUTE,
    USERS_PRODUCTIVITY_REPORT_ROUTE,
    USERS_PROFILE_ROUTE,
} from './consts';

import { hasAnyPermissions } from '../../../utils/helpers';
import { withFormRenderWrapper } from '../../../HOCs/FormRenderWrapper';
import { EMPTY_TITLE_CRUMB } from '../constants';
import withSettingsTabsWrapper from '../settings/components/withSettingsTabsWrapper';

export default (app) => ({
    users: {
        path: USERS_INDEX_ROUTE,
        title: TITLE_PREFIX,
        permission: ['users.index'],
        breadcrumbConfig: { title: `${TITLE_PREFIX}`, clearPath: true },
        loader: () => import('@skunexus/users'),
        props: () => ({
            detailsPageRoute: USERS_DETAILS_ROUTE,
            addFormPageRoute: USERS_CREATE_FORM_ROUTE,
            updateFormPageRoute: USERS_EDIT_FORM_ROUTE,
            changePasswordFormPageRoute: USERS_CHANGE_PASSWORD_FORM_ROUTE,
            permissions: {
                // Header actions
                canAdd: hasAnyPermissions('users.create'),
                withExport: true,
                canEditCustomAttributes: hasAnyPermissions('users - custom attributes.value edit'),
                // Row actions
                canEdit: hasAnyPermissions('users.update'),
                canShow: hasAnyPermissions('users.show'),
                canDelete: hasAnyPermissions('users.delete'),
                canChangePassword: hasAnyPermissions('users.change-password'),
                // Row links
                withUserLink: hasAnyPermissions('users.show')
            },
        }),
        render: withSettingsTabsWrapper(app),
    },
    userForm: {
        path: USERS_FORM_PATH,
        title: TITLE_PREFIX,
        permission: ['users.create', 'users.update'],
        breadcrumbConfig: { title: 'Add User', isReplaceble: true },
        loader: () => import('@skunexus/user-form'),
        props: () => ({
            gridPageRoute: USERS_INDEX_ROUTE,
            permissions: {
                canAdd: hasAnyPermissions('users.create'),
                canEdit: hasAnyPermissions('users.update'),
                canEditCustomValues: hasAnyPermissions('users - custom attributes.value edit')
            },
        }),
        render: withFormRenderWrapper(app)
    },
    userDetails: {
        path: USERS_DETAILS_PATH,
        title: `${TITLE_PREFIX} Details`,
        permission: ['users.show'],
        breadcrumbConfig: { title: EMPTY_TITLE_CRUMB, isReplaceble: true },
        loader: () => import('@skunexus/user-details'),
        props: () => ({
            gridPageRoute: USERS_INDEX_ROUTE,
            updateFormPageRoute: USERS_EDIT_FORM_ROUTE,
            changePasswordFormPageRoute: USERS_CHANGE_PASSWORD_FORM_ROUTE,
            permissions: {
                canEdit: hasAnyPermissions('users.update'),
                canChangePassword: hasAnyPermissions('users.change-password'),
            },
        }),
    },
    userChangePasswordForm: {
        path: USERS_CHANGE_PASSWORD_FORM_PATH,
        title: `${TITLE_PREFIX} - Change Password`,
        permission: ['users.change-password'],
        breadcrumbConfig: { title: 'Change Password', isReplaceble: true },
        loader: () => import('@skunexus/user-change-password'),
        props: () => ({
            gridPageRoute: USERS_INDEX_ROUTE,
        }),
    },
    userProfile: {
        path: USERS_PROFILE_ROUTE,
        title: PROFILE_TITLE_PREFIX,
        permission: [],
        breadcrumbConfig: { title: 'Profile', isReplaceble: true, clearPath: true },
        loader: () => import('@skunexus/user-profile'),
        props: () => ({
            currentUser: UserModel.getCurrentUser(),
            isLocalizeLanguageAvailable: get(app.general.getItem('localizejs'), 'enabled', false),
        }),
    },
    userProductivityReport: {
        path: USERS_PRODUCTIVITY_REPORT_ROUTE,
        title: PRODUCTIVITY_REPORT_TITLE_PREFIX,
        permission: ['users - productivity reports.index'],
        breadcrumbConfig: { title: `${PRODUCTIVITY_REPORT_TITLE_PREFIX}`, clearPath: true },
        loader: () => import('@skunexus/user-productivity-report'),
        props: () => ({
            userDetaisPageRotue: USERS_DETAILS_ROUTE,
            permissions: {
                withUserLink: hasAnyPermissions('users.show'),
            },
        }),
    },
    rolesSettings: {
        path: app.settingsTabs.getItem('roles').link,
        title: app.settingsTabs.getItem('roles').title,
        permission: app.settingsTabs.getItem('roles').permission,
        breadcrumbConfig: { title: `${app.settingsTabs.getItem('roles').label}`, clearPath: true },
        loader: () => import('@skunexus/roles'),
        props: () => ({
            permissions: {
                canAdd: hasAnyPermissions('roles.create'),
                withExport: true,
                canEdit: hasAnyPermissions('roles.update'),
                canDelete: hasAnyPermissions('roles.delete'),
            },
        }),
        render: withSettingsTabsWrapper(app),
    },
});
