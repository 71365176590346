import {
    CUSTOMERS_TITLE_PREFIX,
    CUSTOMERS_INDEX_ROUTE,
    CUSTOMERS_DETAILS_PATH,
    CUSTOMERS_DETAILS_ROUTE,
    CUSTOMERS_FORM_PATH,
    CUSTOMERS_CREATE_FORM_ROUTE,
    CUSTOMERS_EDIT_FORM_ROUTE,
    CUSTOMERS_ADDRESS_PATH,
    CUSTOMERS_CREATE_ADDRESS_ROUTE,
    CUSTOMERS_EDIT_ADDRESS_ROUTE,
} from './consts';

import { withFormRenderWrapper } from '../../../HOCs/FormRenderWrapper';
import { ORDERS_DETAILS_ROUTE } from '../orders/consts';
import { EMPTY_TITLE_CRUMB } from '../constants';
import { hasAnyPermissions } from '../../../utils/helpers';

export default (app) => ({
    customerAddressForm: {
        path: CUSTOMERS_ADDRESS_PATH,
        title: `${CUSTOMERS_TITLE_PREFIX} Address Form`,
        permission: ['customers - addresses.create', 'customers - addresses.update'],
        breadcrumbConfig: { title: 'Add address', isReplaceble: true },
        loader: () => import('@skunexus/customer-address-form'),
        props: () => ({
            permissions: {
                canAdd: hasAnyPermissions('customers - addresses.create'),
                canEdit: hasAnyPermissions('customers - addresses.update'),
            },
            detailsPageRoute: CUSTOMERS_DETAILS_ROUTE,
        }),
        render: withFormRenderWrapper(app)
    },
    customersForm: {
        path: CUSTOMERS_FORM_PATH,
        title: `${CUSTOMERS_TITLE_PREFIX} Form`,
        permission: ['customers.create', 'customers.update'],
        breadcrumbConfig: { title: 'Add Customer', isReplaceble: true },
        loader: () => import('@skunexus/customer-form'),
        props: () => ({
            permissions: {
                canAdd: hasAnyPermissions('customers.create'),
                canEdit: hasAnyPermissions('customers.update'),
                canEditCustomValues: hasAnyPermissions('customers - custom attributes.value edit'),
            },
            gridPageRoute: CUSTOMERS_INDEX_ROUTE,
        }),
        render: withFormRenderWrapper(app)
    },
    customerDetails: {
        path: CUSTOMERS_DETAILS_PATH,
        title: `${CUSTOMERS_TITLE_PREFIX} Details`,
        permission: ['customers.show'],
        breadcrumbConfig: { title: EMPTY_TITLE_CRUMB, isReplaceble: true },
        loader: () => import('@skunexus/customer-details'),
        props: () => ({
            gridPageRoute: CUSTOMERS_INDEX_ROUTE,
            updateFormPageRoute: CUSTOMERS_EDIT_FORM_ROUTE,
            addAddressFormPageRoute: CUSTOMERS_CREATE_ADDRESS_ROUTE,
            updateAddressFormPageRoute: CUSTOMERS_EDIT_ADDRESS_ROUTE,
            orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
            permissions: {
                canActivate: hasAnyPermissions('customers.update'),
                canDeactivate: hasAnyPermissions('customers.update'),
                canEditCustomer: hasAnyPermissions('customers.update'),
                customerAddress: {
                    canAdd: hasAnyPermissions('customers - addresses.create'),
                    canEdit: hasAnyPermissions('customers - addresses.update'),
                    canDelete: true
                },
                canShowCustomerAddressGrid: hasAnyPermissions([
                    'customers - addresses.create',
                    'customers - addresses.update',
                    'customers - addresses.show'
                ]),
                canShowCustomerOrderGrid: hasAnyPermissions('orders.index'),
                withOrderLink: hasAnyPermissions('orders.show')
            }
        }),
    },
    customers: {
        path: CUSTOMERS_INDEX_ROUTE,
        title: `${CUSTOMERS_TITLE_PREFIX}s`,
        permission: ['customers.index'],
        breadcrumbConfig: { title: `${CUSTOMERS_TITLE_PREFIX}s`, clearPath: true },
        loader: () => import('@skunexus/customers-grid'),
        props: () => ({
            detailsPageRoute: CUSTOMERS_DETAILS_ROUTE,
            addFormPageRoute: CUSTOMERS_CREATE_FORM_ROUTE,
            updateFormPageRoute: CUSTOMERS_EDIT_FORM_ROUTE,
            permissions: {
                canAdd: hasAnyPermissions('customers.create'),
                withExport: true,
                canEditCustomAttributes: hasAnyPermissions('customers - custom attributes.value edit'),
                canActivate: hasAnyPermissions('customers.update'),
                canDeactivate: hasAnyPermissions('customers.update'),
                canEdit: hasAnyPermissions('customers.update'),
                canShow: hasAnyPermissions('customers.show'),
                withCustomerLink: hasAnyPermissions('customers.show')
            }
        }),
    },
});
