import { COMPANY_LABEL, COMPANY_ROUTE, EMAIL_NOTIFICATIONS_LABEL, EMAIL_NOTIFICATIONS_ROUTE } from './consts';

export default () => ({
    company: {
        id: 'company',
        label: COMPANY_LABEL,
        title: `${COMPANY_LABEL} Settings`,
        link: COMPANY_ROUTE,
        permission: 'settings - general.save',
    },
    emailNotifcations: {
        id: 'emailNotifcations',
        label: EMAIL_NOTIFICATIONS_LABEL,
        title: EMAIL_NOTIFICATIONS_LABEL,
        link: EMAIL_NOTIFICATIONS_ROUTE,
        permission: 'settings - general.save',
    },
    // DISABLED FOR NOW, UNTIL WE START TO USE IT
    // currency: {
    //     id: 'currency',
    //     label: CURRENCY_LABEL,
    //     title: `Global ${CURRENCY_LABEL}`,
    //     link: CURRENCY_ROUTE,
    //     permission: 'settings - general.save',
    // },
    adjustmentStockReasons: {
        id: 'adjustmentStockReasons',
        label: 'Adjustment Stock Reasons',
        title: 'Adjustment Stock Reasons',
        link: '/settings/global/adjustment-stock-reasons',
        permission: 'settings - general.save',
    },
    kittingSettings: {
        id: 'kittingSettings',
        label: 'Kitting',
        title: 'Kitting Settings',
        link: '/settings/global/kitting',
        permission: 'settings - general.save',
    },
});
