import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, omit } from 'lodash';

import { CurrentUserModel } from '@skunexus/auth';
import { MainLayout } from '@skunexus/component-catalog';
import { actions as appActions } from '@skunexus/utils';

import { useConfigContext } from '../contexts/ConfigContext';
import UserModel from '../UserModel';

import app from '../config';

export const value = (item) => (item instanceof Function ? item() : item);

export const keyedRoutes = (routes) =>
    Object.keys(routes).map((key) => ({
        name: get(routes[key], 'name', key),
        ...routes[key],
    }));

export const filterAccessibleItems = (items) =>
    value(items)
        .filter((item) => {
            let permissions = value(get(item, 'permission', []));
            if (!Array.isArray(permissions)) {
                permissions = [permissions];
            }
            return !permissions.length || CurrentUserModel.hasAnyPermissions(permissions);
        })
        .map((item) => {
            const { children = [] } = item;

            if (!children.length) {
                return item;
            }

            return {
                ...item,
                children: filterAccessibleItems(children),
            };
        });

export const combinePermissions = (items) =>
    value(items).reduce((result, item) => {
        const permissions = value(get(item, 'permission', []));
        return result.concat(Array.isArray(permissions) ? permissions : [permissions]);
    }, []);

export const hasAllPermissions = (permissions) => UserModel.getCurrentUser().hasAllPermissions(permissions);
export const hasAnyPermissions = (permissions) => UserModel.getCurrentUser().hasAnyPermissions(permissions);

export const firstAllowedLink = (items) => {
    const item = filterAccessibleItems(items).shift();
    return item !== undefined ? value(item.link) : '';
};

export const createMainLayout = (layoutProps) => {
    const mapStateToProps = (state) => ({
        logoImage: app.general.getItem('logoImage'),
        pageName: app.selectors.getItem('getPageName')(state),
        breadcrumbs: app.selectors.getItem('getBreadcrumbs')(state),
        isSidebarOpened: app.selectors.getItem('getIsSidebarMenuOpen')(state),
        ...value(layoutProps),
        userName: `${get(CurrentUserModel.getExtra(), 'first_name', UserModel.isVendor() ? 'Vendor' : 'User')} ${get(
            CurrentUserModel.getExtra(),
            'last_name',
            '',
        )}`.trim(),
        userImage: {
            url: `${get(CurrentUserModel.getExtra(), 'profile_image.url', null)}`,
            type: `${get(CurrentUserModel.getExtra(), 'profile_image.type', null)}`,
        },
        roleName: CurrentUserModel.getRoles().join(', '),
    });

    const mapDispatchToProps = (disaptch) =>
        bindActionCreators(
            {
                toggleSidebar: appActions.toggleSidebarMenu,
            },
            disaptch,
        );

    return connect(
        mapStateToProps,
        mapDispatchToProps,
    )(({ sideMenuItems, ...props }) => {
        const [{ version }] = useConfigContext();

        const accessibleItems = filterAccessibleItems(sideMenuItems).map((item) => {
            const newItem = {
                ...item,
                items: filterAccessibleItems(get(item, 'items', [])),
            };

            if (!newItem.items.length) {
                return omit(newItem, ['items']);
            }

            return newItem;
        });

        return <MainLayout {...props} sideMenuItems={accessibleItems} version={version} />;
    });
};
